.paneles{
    display: flex;
    width: 90%;
    margin: 0 auto;
    gap: 20px;
    text-align: left;
}

.panele div{
    flex: 0 0 auto;
    padding: 1rem;
    width: 50%;
}

.p-panel .p-panel-header{
    background: #4871BF !important;
    color: #fff !important;
}

.hora{
    border-bottom: 1px solid #000;
    margin: 20px;
    text-align: center;
}

.tituloInput{
    font-weight: 700;
    margin: 10px 0 2px 0;
}
.btn{
    margin: 15px auto !important;
}

.color-input{
    color: red;
}